import React, { useEffect, useState } from 'react'
import { FieldError, FieldValues, useForm } from 'react-hook-form'

import { upload } from '../../lib/fileUpload'
import { createIssueFromFormData, postIssue } from '../../lib/services/issue'
import { ApplicationType } from '../../model/Application'
import { Product } from '../../model/Product'
import { IssueType } from '../../model/Requests'
import ButtonGroup from '../ButtonGroup/ButtonGroup'
import FileGroup from '../FileGroup/FileGroup'
import MessageModal, { ModalContent } from '../MessageModal/MessageModal'
import styles from './ReportForm.module.scss'

type Props = {
  product: Product
}

type IssueState = {
  type: IssueType
  label: string
  buttonText: string
  descriptionPlaceholderText: string
}

const inputStates: IssueState[] = [
  {
    type: IssueType.issue,
    label: 'Issue',
    buttonText: 'Report Issue',
    descriptionPlaceholderText: 'Description of the issue'
  },
  {
    type: IssueType.feature,
    label: 'Feature',
    buttonText: 'Request Feature',
    descriptionPlaceholderText: 'What would make the product better?'
  },
  {
    type: IssueType.issue,
    label: 'Report an Issue',
    buttonText: 'Report Issue',
    descriptionPlaceholderText: 'Description of the issue'
  },
  {
    type: IssueType.feature,
    label: 'General Feedback',
    buttonText: 'Submit Feedback',
    descriptionPlaceholderText: 'What would make the product better?'
  }
]

enum SubmitState { pending, complete, working }
// eslint-disable-next-line @typescript-eslint/ban-types
const buttonTextRecords: Record<SubmitState, Function> = {
  [SubmitState.pending]: (state: IssueState) => state.buttonText,
  [SubmitState.complete]: () => 'Thanks!',
  [SubmitState.working]: () => 'Working...'
}

function getProductIssueStates({ name }: Product) {
  if (name === 'localization' || name === 'ai') {
    return [inputStates[3], inputStates[2]]
  }

  return [inputStates[0], inputStates[1]]
}

const ReportForm = ({ product }: Props): React.ReactElement => {
  // UI state
  const [availableInputStates]                = useState(getProductIssueStates(product))
  const [activeIssueType, setActiveIssueType] = useState(availableInputStates[0])
  const [resultModalOpen, setResultModalOpen] = useState(false)
  const [modalContent, setModalContent]       = useState<ModalContent>()
  const [submitState, setSubmitState]         = useState(SubmitState.pending)
  const [application, setApplication]         = useState<string | undefined>(undefined)

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const application = searchParams.get('application') ?? undefined

    if (application === ApplicationType.rise || application === ApplicationType.storyline) {
      setApplication(application)
    }
  }, [])

  // Form state
  const [currentFiles, setCurrentFiles] = useState<File[]>([])
  const { register, handleSubmit, reset, formState: { errors } } = useForm()

  const submit = async(data: FieldValues) => {
    setSubmitState(SubmitState.working)

    const issue = createIssueFromFormData(data, product, activeIssueType.type, currentFiles.map(file => file.name), application)

    try {
      const response = await postIssue(issue)
      const uploadUrls = response.uploadUrls ?? []
      const failedFiles: string[] = []

      if (issue.type === IssueType.issue && uploadUrls.length > 0 && currentFiles) {
        for (const uploadUrl of uploadUrls) {
          const file = currentFiles.find(file => file.name === uploadUrl.fileName)

          if (file) {
            setModalContent({ message: `uploading ${uploadUrl.fileName}...`, title: 'Working' })
            await upload(uploadUrl.uploadUrl, file)
              .then(res => res.status)
              .catch(() => {
                failedFiles.push(file.name)
              })
          }
        }
      }

      const message = issue.type === IssueType.issue
        ? `Issue "${response.issueId}" has been created` + ((failedFiles.length > 0)
          ? ` but the following files failed to upload: ${failedFiles.join(', ')}.`
          : '.')
        : 'Your feedback has been submitted.'

      setModalContent({ title: 'We hear you!', message })
      setSubmitState(SubmitState.pending)
    } catch (error: unknown) {
      setModalContent({ title: 'Oops', message: (error as Error).message })
    }

    setSubmitState(SubmitState.complete)
    reset()
  }

  useEffect(() => {
    modalContent && setResultModalOpen(true)
  },
  [modalContent])

  useEffect(() => {
    if (!resultModalOpen) {
      setModalContent(undefined)
      setSubmitState(SubmitState.pending)
    }
  },
  [resultModalOpen])

  const onActiveIssueTypeChangeRequested = (issueType: string) => {
    const requestedState = inputStates.find(state => state.label === issueType)
    setActiveIssueType(requestedState || availableInputStates[0])
  }

  const selectedFilesChanged = (files: File[]) => {
    setCurrentFiles(files)
  }

  const getMessageForEmailError = (error: FieldError): string => {
    const emailErrorMessages: { [key: string]: string } = {
      required: 'Please add your email',
      pattern: 'Please enter a valid email'
    }

    return emailErrorMessages[error?.type] || emailErrorMessages.pattern
  }

  return (
    <>
      <div className={styles.section}>
        <ButtonGroup elements={availableInputStates.map(issueState => issueState.label)}
          activeElement={activeIssueType.label}
          onElementChangeRequested={onActiveIssueTypeChangeRequested} />
      </div>

      <form onSubmit={handleSubmit(submit)} className={styles.reportForm} noValidate>
        <div className={styles.section}>
          <div className={styles.inputFloatLabel}>
            <div
              className={`art-font-body-xsm validationMessage ${errors.userName ? '' : 'hidden'}`}
            >
              Please add your name
            </div>
            <input {...register('userName', { required: true, minLength: 2 })} id="userName" placeholder=" " autoComplete="email" className="default"/>
            <label htmlFor="userName">Your Name</label>
          </div>
          <div className={styles.inputFloatLabel}>
            <div className={`art-font-body-xsm validationMessage ${errors.email ? '' : 'hidden'}`}>
              { getMessageForEmailError(errors.email)}
            </div>
            <input {...register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} id="email" type="email" placeholder=" " autoComplete="email" className="default" />
            <label htmlFor="email">Email</label>
          </div>
          <div className={styles.inputFloatLabel}>
            <div className={`art-font-body-xsm validationMessage ${errors.issueTitle ? '' : 'hidden'}`}>
              Please add a summary
            </div>
            <input {...register('issueTitle', { required: true, minLength: 2 })} id="issueTitle" placeholder=" " autoComplete="off" className="default" />
            <label htmlFor="issueTitle">Summary</label>
          </div>
        </div>

        <div className={styles.section}>
          <label htmlFor="issueDescription" className="textareaLabel">{activeIssueType.descriptionPlaceholderText}</label>
          <textarea {...register('issueDescription', { required: true, minLength: 2 })} id="issueDescription" className="default" rows={8}/>
          <div className={`art-font-body-xsm validationMessage ${errors.issueDescription ? '' : 'hidden'}`}>
            Please add a description
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.filePickerContainer}>
            <FileGroup onSelectedFilesChanged={selectedFilesChanged} />
          </div>
        </div>

        <input className={`art-font-body-lg ${styles.submitButton}`}
          disabled={submitState !== SubmitState.pending}
          type="submit"
          value={buttonTextRecords[submitState](activeIssueType)}
        />
      </form>

      <MessageModal modalContent={modalContent} open={resultModalOpen} setOpen={setResultModalOpen} />
    </>
  )
}

export default ReportForm
